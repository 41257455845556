<script setup lang="ts">
// types
import type { Nullable } from '@revolutionprep/types'

// vue
import { computed, ref, useAttrs, watch } from 'vue'

/**
 * props
 * ==================================================================
 */
interface Props {
  disabled?: boolean
  formValidation?: boolean
  payloadProperty?: Nullable<string>
  readonly?: boolean
  rows?: number
  showErrorMessages?: boolean
  value?: string
  vid: string
}
const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  formValidation: false,
  payloadProperty: null,
  readonly: false,
  rows: 4,
  showErrorMessages: true,
  value: ''
})

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits([
  'blur',
  'input',
  'input-update-value'
])

/**
 * fallthrough attributes
 * ==================================================================
 */
const attrs = useAttrs()

/**
 * state
 * ==================================================================
 */
const innerValue = ref('')

/**
 * computed
 * ==================================================================
 */
const isInputValid = computed(() => {
  const validationError = attrs['error-messages']

  if (
    !props.formValidation || validationError) {
    return false
  }

  const dirty = attrs?.dirty
  const valid = attrs?.valid

  if (dirty && valid) {
    return true
  }
  return false
})

/**
 * watchers
 * ==================================================================
 */
watch(innerValue, (newValue: string) => {
  emit('input', newValue)
})

watch(() => props.value, (newValue: string) => {
  innerValue.value = newValue
})

/**
 * methods
 * ==================================================================
 */
function blur () {
  emit('blur')
}

function save (newValue: string) {
  emit('input-update-value', {
    [props.payloadProperty || props.vid]: newValue
  })
}

/**
 * lifecycle hooks
 * ==================================================================
 */
if (props.value) {
  innerValue.value = props.value
}
</script>

<template>
  <v-textarea
    v-model="innerValue"
    v-bind="$attrs"
    variant="outlined"
    hide-details
    :rows="rows"
    :color="isInputValid ? 'success' : 'grey-darken-3'"
    :disabled="disabled"
    :readonly="readonly"
    @update:model-value="save"
    @blur="blur()"
  />
  <span
    v-if="$attrs['error-messages'] && showErrorMessages"
    class="text-red ms-2"
  >
    {{ $attrs['error-messages'] }}
  </span>
</template>
